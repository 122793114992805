import React from "react"
import ModalVideo from "react-modal-video"
import { Link } from "gatsby"
// import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import LogoImage from "../components/logoImage"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import IconPlayVideo from "../images/icon-video-play-inline.svg"


export class IndexPage extends React.Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    this.setState({ isOpen: true })
  }

  render() {
    return (
      <Layout>
        <SEO title="Football Prenup" />
        <div id="main">
          <Container>
            <Row className="intro">
              <Col>
                <LogoImage />
                <p>
                  This season, don’t get blindsided by everyday life. Save
                  football. Save your relationship. Save America with a Football
                  Prenup.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Link to="/personalized-prenup/" className="btn btn-draft">
                  Draft your prenup
                </Link>
              </Col>
            </Row>
            <Row>
              <Col>
                <ModalVideo
                  channel="youtube"
                  isOpen={this.state.isOpen}
                  videoId="7Q4jYWnWdl4"
                  onClose={() => this.setState({ isOpen: false })}
                />
                <button className="btn btn-play-video" onClick={this.openModal}>
                  <IconPlayVideo /> Learn more about the prenup
                </button>
              </Col>
            </Row>
          </Container>
          <Footer />
        </div>
      </Layout>
    )
  }
}

export default IndexPage
